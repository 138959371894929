<template>
  <a-row align="middle" :gutter="8" v-if="finalLabel">
      <a-col :span="2" align="right">
        <span>{{ label }}</span>
      </a-col>
      <a-col :span="20">
        <Slider
            @update="notify"
            showTooltip="focus"
            :step="steps"
            :min="0"
            :max="max"
            :format="formatTooltip"
            v-model="value"
        ></Slider>
      </a-col>
    <a-col :span="2" align="left">
      <span>{{ finalLabel }}</span>
    </a-col>
  </a-row>
  <a-row align="middle" :gutter="8" v-else>
    <a-col :span="8" align="left">
      <span>{{ label }}</span>
    </a-col>
    <a-col :span="16">
      <Slider
          showTooltip="focus"
          @update="notify"
          :step="steps"
          :min="0"
          :max="max"
          :format="formatTooltip"
          v-model="value"
      ></Slider>
    </a-col>
  </a-row>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { useModelWrapper } from '@/composables/useModelWrapper';
import Slider from '@vueform/slider';

const props = defineProps({
    label: String,
    finalLabel: String,
    max: Number,
    steps: Number,
    ranges: Array,
    modelValue: Number,
  });

const emit = defineEmits(['update:modelValue']);

const value = useModelWrapper(props, emit);

const formatTooltip = (value) => {
  return props.ranges[value]
}
</script>

<style lang="scss">
@import "./src/static/css/colors";
.slider-target,
.slider-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.slider-target {
  position: relative;
}

.slider-base,
.slider-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.slider-connects {
  overflow: hidden;
  z-index: 0;
}

.slider-connect,
.slider-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}

.slider-txt-dir-rtl.slider-horizontal .slider-origin {
  left: 0;
  right: auto;
}

.slider-vertical .slider-origin {
  top: -100%;
  width: 0;
}

.slider-horizontal .slider-origin {
  height: 0;
}

.slider-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.slider-touch-area {
  height: 100%;
  width: 100%;
  background-color: $text-background-color;
  border-radius: 100px;
}

.slider-state-tap .slider-connect,
.slider-state-tap .slider-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.slider-state-drag * {
  cursor: inherit !important;
}

.slider-tooltip-focus .slider-tooltip,
.slider-tooltip-drag .slider-tooltip {
  display: none !important;
}

.slider-tooltip-focus.slider-focused .slider-tooltip:not(.slider-tooltip-hidden),
.slider-tooltip-drag.slider-state-drag .slider-tooltip:not(.slider-tooltip-hidden),
.slider-tooltip-drag .slider-active .slider-tooltip {
  display: block !important;
}

.slider-horizontal {
  height: var(--slider-height, 6px);
}

.slider-horizontal .slider-handle {
  width: var(--slider-handle-width, 16px);
  height: var(--slider-handle-height, 16px);
  top: calc(((var(--slider-handle-height, 16px) - var(--slider-height, 6px)) / 2 + 1px) * (-1));
  right: calc(var(--slider-handle-width, 16px) / 2 * (-1));
}

.slider-vertical {
  width: var(--slider-height, 6px);
  height: var(--slider-vertical-height, 300px);
}

.slider-vertical .slider-handle {
  width: var(--slider-handle-height, 16px);
  height: var(--slider-handle-width, 16px);
  right: calc(((var(--slider-handle-height, 16px) - var(--slider-height, 6px)) / 2 + 1px) * (-1));
  bottom: calc(var(--slider-handle-width, 16px) / 2 * (-1));
}

.slider-txt-dir-rtl.slider-horizontal .slider-handle {
  left: calc(var(--slider-handle-width, 16px) / 2 * (-1));
  right: auto;
}

.slider-base {
  background-color: var(--slider-bg, #D1D5DB);
  border-radius: var(--slider-radius, 9999px);
}

.slider-connects {
  border-radius: var(--slider-radius, 9999px);
}

.slider-connect {
  background: var(--slider-connect-bg);
  cursor: pointer;
}

.slider-draggable {
  cursor: ew-resize;
}

.slider-vertical .slider-draggable {
  cursor: ns-resize;
}

.slider-handle {
  width: var(--slider-handle-width, 16px);
  height: var(--slider-handle-height, 16px);
  border-radius: var(--slider-handle-radius, 9999px);
  background: var(--slider-handle-bg, #fff);
  border: var(--slider-handle-border, 0);
  box-shadow: var(--slider-handle-shadow, 0.5px 0.5px 2px 1px rgba(0,0,0,.32));
  cursor: grab;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 var(--slider-handle-ring-width, 3px) var(--slider-handle-ring-color), var(--slider-handle-shadow, 0.5px 0.5px 2px 1px rgba(0,0,0,.32));
  }
}

.slider-active {
  box-shadow: var(--slider-handle-shadow-active, 0.5px 0.5px 2px 1px rgba(0,0,0,.42));
  cursor: grabbing;
}

[disabled] .slider-connect {
  background: var(--slider-connect-bg-disabled, #9CA3AF);
}

[disabled].slider-target,
[disabled].slider-handle,
[disabled] .slider-handle {
  cursor: not-allowed;
}

[disabled] .slider-tooltip {
  background: var(--slider-tooltip-bg-disabled, #9CA3AF);
  border-color: var(--slider-tooltip-bg-disabled, #9CA3AF);
}

.slider-tooltip {
  position: absolute;
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  white-space: nowrap;
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  min-width: var(--slider-tooltip-min-width, 20px);
  text-align: center;
  color: var(--slider-tooltip-color, #fff);
  border-radius: var(--slider-tooltip-radius, 5px);
  border: 1px solid var(--slider-tooltip-bg, $text-background-color);
  background: var(--slider-tooltip-bg, $text-background-color);
}

.slider-horizontal .slider-tooltip-top {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: calc(var(--slider-handle-height, 16px) + var(--slider-tooltip-arrow-size, 5px) + var(--slider-tooltip-distance, 3px));

  &:before {
    content: "";
    position: absolute;
    bottom: calc(var(--slider-tooltip-arrow-size, 5px) * (-2));
    left: 50%;
    width: 0;
    height: 0;
    border: var(--slider-tooltip-arrow-size, 5px) solid transparent;
    border-top-color: inherit;
    transform: translate(-50%);
  }
}

.slider-horizontal .slider-tooltip-bottom {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  top: calc(var(--slider-handle-height, 16px) + var(--slider-tooltip-arrow-size, 5px) + var(--slider-tooltip-distance, 3px));

  &:before {
    content: "";
    position: absolute;
    top: calc(var(--slider-tooltip-arrow-size, 5px) * (-2));
    left: 50%;
    width: 0;
    height: 0;
    border: var(--slider-tooltip-arrow-size, 5px) solid transparent;
    border-bottom-color: inherit;
    transform: translate(-50%);
  }
}

.slider-vertical .slider-tooltip-left {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: calc(var(--slider-handle-height, 16px) + var(--slider-tooltip-arrow-size, 5px) + var(--slider-tooltip-distance, 3px));

  &:before {
    content: "";
    position: absolute;
    right: calc(var(--slider-tooltip-arrow-size, 5px) * (-2));
    top: 50%;
    width: 0;
    height: 0;
    border: var(--slider-tooltip-arrow-size, 5px) solid transparent;
    border-left-color: inherit;
    transform: translateY(-50%);
  }
}

.slider-vertical .slider-tooltip-right {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  left: calc(var(--slider-handle-height, 16px) + var(--slider-tooltip-arrow-size, 5px) + var(--slider-tooltip-distance, 3px));

  &:before {
    content: "";
    position: absolute;
    left: calc(var(--slider-tooltip-arrow-size, 5px) * (-2));
    top: 50%;
    width: 0;
    height: 0;
    border: var(--slider-tooltip-arrow-size, 5px) solid transparent;
    border-right-color: inherit;
    transform: translateY(-50%);
  }
}

.slider-horizontal .slider-origin > .slider-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
}

.slider-horizontal .slider-origin > .slider-tooltip-top {
  bottom: calc(var(--slider-tooltip-arrow-size, 5px) + ((var(--slider-handle-height, 16px) - var(--slider-height, 6px)) / 2) + var(--slider-tooltip-distance, 3px) + 1px);
}

.slider-horizontal .slider-origin > .slider-tooltip-bottom {
  top: calc(var(--slider-tooltip-arrow-size, 5px) + ((var(--slider-handle-height, 16px) - var(--slider-height, 6px)) / 2) + var(--slider-tooltip-distance, 3px) + var(--slider-height, 6px) - 1px);
}

.slider-vertical .slider-origin > .slider-tooltip {
  transform: translate(0, calc((var(--slider-tooltip-line-height, 1.25rem) - var(--slider-tooltip-py, 2px)) * (-1) + 1px));
  top: auto;
}

.slider-vertical .slider-origin > .slider-tooltip-left {
  right: calc(var(--slider-tooltip-arrow-size, 5px) + var(--slider-height, 6px) + ((var(--slider-handle-height, 16px) - var(--slider-height, 6px)) / 2) + var(--slider-tooltip-distance, 3px) - 1px);
}

.slider-vertical .slider-origin > .slider-tooltip-right {
  left: calc(var(--slider-tooltip-arrow-size, 5px) + var(--slider-height, 6px) + ((var(--slider-handle-height, 16px) - var(--slider-height, 6px)) / 2) + var(--slider-tooltip-distance, 3px) - var(--slider-height, 6px) + 1px);
}

</style>
